import { useMemo, useRef, useState } from "react";
import { TravelRequestSubmission, TripTypes } from "./TravelRequestSubmission";
import dayjs from "dayjs";
import arrayMutators from "final-form-arrays";
import { TravelRequestFields } from "./TravelRequestFields";
import { SubmitButton } from "../common/components/SubmitButton";
import SaveIcon from "@mui/icons-material/Save";
import { Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { Form } from "react-final-form";
import {
  approve,
  estimate,
  reopen,
  submit,
  update,
} from "./TravelRequestionSubmissionFunctions";
import { useNavigate } from "react-router-dom";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { TravelFormPopupDialogs } from "./TravelFormPopupDialogs";

export interface TravelFormWrapperProps {
  editableTravelRequest?: TravelRequestSubmission;
  reloadRequest: () => void;
  id?: number;
  canEdit?: boolean;
  canEstimate?: boolean;
  canApprove?: boolean;
  canReopen?: boolean;
  error: () => void;
}

export function TravelFormWrapper({
  editableTravelRequest,
  reloadRequest,
  id,
  canEstimate,
  canEdit,
  canApprove,
  canReopen,
}: TravelFormWrapperProps) {
  const navigate = useNavigate();

  const initialValues = useMemo(
    () => ({
      timezone: dayjs.tz.guess(),
      tripType: TripTypes.oneWay,
      tripSegments: [{ tripEstimates: {}, tripSegment: {} }],
      ...editableTravelRequest,
    }),
    []
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmReopen, setShowConfirmReopen] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const confirmFunction = useRef(async () => {});

  return (
    <Form<TravelRequestSubmission>
      initialValues={initialValues}
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, values, invalid }) => (
        <form onSubmit={handleSubmit}>
          <TravelRequestFields
            disabled={editableTravelRequest != null && !canEdit}
            canEstimate={canEstimate}
            existingRequest={id !== undefined} //if this isnt a new request show these
          />
          <Stack spacing={2} direction={"row"}>
            {canEstimate && (
              <SubmitButton
                type="button"
                disabled={invalid}
                isSubmitting={submitting}
                icon={<SaveIcon />}
                onClick={() => {
                  confirmFunction.current = async function () {
                    await estimate(id, values);
                    navigate("/success");
                  };
                  setShowConfirm(true);
                }}
                text="Submit Estimates"
              />
            )}

            {canEdit && (
              <SubmitButton
                type="button"
                disabled={invalid}
                isSubmitting={submitting}
                icon={<SaveIcon />}
                onClick={() => {
                  confirmFunction.current = async function () {
                    await update(id, values);
                    navigate("/success");
                  };
                  setShowConfirm(true);
                }}
                text="Save Trip Segments"
              />
            )}

            {canApprove && (
              <SubmitButton
                type="button"
                disabled={invalid}
                isSubmitting={submitting}
                color="success"
                icon={<CheckIcon />}
                onClick={() => {
                  confirmFunction.current = async function () {
                    await approve(id);
                    navigate("/success");
                  };
                  setShowConfirm(true);
                }}
                text="Approve Travel Request"
              />
            )}
            {canApprove && (
              <SubmitButton
                type="button"
                disabled={invalid}
                isSubmitting={submitting}
                color="error"
                icon={<CancelIcon />}
                onClick={() => {
                  setShowReject(true);
                }}
                text="Reject Travel Request"
              />
            )}

            {!id && (
              <SubmitButton
                type="button"
                disabled={invalid}
                isSubmitting={submitting}
                icon={<SaveIcon />}
                onClick={() => {
                  confirmFunction.current = async function () {
                    await submit(values);
                    navigate("/success");
                  };
                  setShowConfirm(true);
                }}
                text="Submit Travel Request"
              />
            )}

            {canReopen && (
              <SubmitButton
                type="button"
                disabled={!canReopen}
                isSubmitting={submitting}
                color="warning"
                icon={<SettingsBackupRestoreOutlinedIcon />}
                onClick={() => {
                  setShowConfirmReopen(true);
                  confirmFunction.current = async function () {
                    await reopen(id);
                    reloadRequest();
                  };
                }}
                text="Reopen Travel Request"
              />
            )}
          </Stack>
          <TravelFormPopupDialogs
            id={id}
            tripSegments={values.tripSegments ?? []}
            submitFunction={async () => {
              setSubmitting(true);
              await confirmFunction.current();
              setSubmitting(false);
            }}
            submitting={submitting}
            setSubmitting={setSubmitting}
            showConfirm={showConfirm}
            closeConfirm={() => setShowConfirm(false)}
            showReopen={showConfirmReopen}
            closeReopen={() => setShowConfirmReopen(false)}
            showReject={showReject}
            closeReject={() => setShowReject(false)}
          />
        </form>
      )}
    />
  );
}
