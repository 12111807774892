import React from "react";
import { Stack } from "@mui/system";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import {
  DateFormField,
  RadioFormField,
  SelectFormField,
  TextFormField,
} from "../common/components/FormFields";
import { EstimatedCostFields } from "./EstimateCost";
import { TravelRequestSubmission } from "./TravelRequestSubmission";
import { useField, useFormState } from "react-final-form";
import dayjs from "dayjs";
import MuiDelete from "@mui/icons-material/Delete";

export interface TripSegmentFieldsProps {
  name: string;
  index: number;
  disabled?: boolean;
  canSeeEstimate?: boolean;
  canEstimate?: boolean;
  removable: boolean;
  removeSegment: () => void;
}

export function TripSegmentFields({
  name,
  index,
  disabled,
  canEstimate,
  canSeeEstimate,
  removable,
  removeSegment,
}: TripSegmentFieldsProps) {
  const {
    values: { tripSegments },
  } = useFormState<TravelRequestSubmission>({ subscription: { values: true } });

  const {
    input: { value: dateOfDeparture },
  } = useField<string | undefined>(`${name}.tripSegment.dateOfDeparture`);

  const inPast = dayjs().subtract(1, "day").isAfter(dayjs(dateOfDeparture));

  const {
    input: { value: showHotel },
  } = useField<string | undefined>(`${name}.tripSegment.hotelRequired`);

  return (
    <Stack key={index}>
      <Grid container>
        <Grid item sm={10}>
          <Typography variant="h6" paragraph>
            Segment {index + 1}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Button
            disabled={!removable || inPast}
            color="error"
            onClick={removeSegment}
            startIcon={<MuiDelete />}
          >
            Remove Segment
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextFormField
            name={`${name}.tripSegment.startingLocation`}
            required
            label="Starting Location"
            size="medium"
            disabled={disabled || inPast}
            noIcon
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFormField
            name={`${name}.tripSegment.destination`}
            required
            label="Destination"
            size="medium"
            disabled={disabled || inPast}
            noIcon
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFormField
            name={`${name}.tripSegment.numberOfDays`}
            label="Number of Days of Stay"
            size="medium"
            disabled={disabled || inPast}
            noIcon
          />
        </Grid>
        <Grid item xs={12} md={showHotel == "Yes" ? 2 : 3}>
          <DateFormField
            name={`${name}.tripSegment.dateOfDeparture`}
            required
            label="Departure Date"
            disabled={disabled || inPast}
            noIcon
            stringOutput
            minDate={
              !(disabled || inPast)
                ? index > 0
                  ? dayjs.max(
                      dayjs(
                        tripSegments?.at(index - 1)?.tripSegment.dateOfDeparture
                      ),
                      dayjs()
                    )
                  : dayjs()
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} md={showHotel == "Yes" ? 2 : 3}>
          <SelectFormField
            name={`${name}.tripSegment.preferredDepartureTime`}
            required
            label="Preferred Departure Time"
            size="medium"
            disabled={disabled || inPast}
            noIcon
            options={[
              { value: "am", text: "am" },
              { value: "pm", text: "pm" },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <RadioFormField
            name={`${name}.tripSegment.rentalRequired`}
            required
            label="Rental Required"
            options={["Yes", "No"]}
            noIcon
            disabled={disabled || inPast}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <RadioFormField
            name={`${name}.tripSegment.flightRequired`}
            required
            label="Flight Required"
            options={["Yes", "No"]}
            noIcon
            disabled={disabled || inPast}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <RadioFormField
            name={`${name}.tripSegment.hotelRequired`}
            required
            label="Hotel Required"
            options={["Yes", "No"]}
            noIcon
            disabled={disabled || inPast}
          />
        </Grid>
        {showHotel == "Yes" && (
          <Grid item xs={12} md={2}>
            <TextFormField
              name={`${name}.tripSegment.hotelName`}
              label="Requested Hotel"
              size="medium"
              disabled={disabled || inPast}
              noIcon
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <EstimatedCostFields
            name={name}
            disabled={disabled}
            canSeeEstimate={canSeeEstimate}
            canEstimate={canEstimate}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
