import { Link, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const Dashboard: React.FC = () => {
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();

  const queryParams = useMemo(() => {
    const params = new URLSearchParams();
    if (fromDate) {
      params.append("fromDate", fromDate);
    }
    if (toDate) {
      params.append("toDate", toDate);
    }
    return params;
  }, [fromDate, toDate]);

  return (
    <>
      <Stack spacing={4}>
        <Stack direction={"row"} justifyContent={"space-evenly"}>
          <DatePicker
            label={"From"}
            format="DD/MM/YYYY"
            value={fromDate ? dayjs(fromDate) : undefined}
            onChange={(value) => setFromDate(value?.format("YYYY-MM-DD"))}
          />
          <DatePicker
            label={"To"}
            format="DD/MM/YYYY"
            value={toDate ? dayjs(toDate) : undefined}
            onChange={(value) => setToDate(value?.format("YYYY-MM-DD"))}
          />
        </Stack>
        <Link
          href={`/internal/cfi-winter-clothing-application-export?${queryParams.toString()}`}
          download
        >
          Export CFI Winter Clothing Applications
        </Link>
        <Link
          href={`/internal/food-program-application-export?${queryParams.toString()}`}
          download
        >
          Export School Foods Program Applications
        </Link>
        <Link
          href={`/internal/cfi-application-export?${queryParams.toString()}`}
          download
        >
          Export CFI Applications
        </Link>
      </Stack>
    </>
  );
};

export default Dashboard;
